import React, { Component } from 'react';

import { Link, Redirect } from 'react-router-dom';

import intl from 'react-intl-universal';
import GlobalVar from '../../GlobalVar';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

class Navigation extends Component {
	
	componentWillMount() {
		this.checkLink(this.props);
	}

	componentWillReceiveProps(nextProps) {
		//this.redirectIfTermsNotAcceptedYet(nextProps);
		this.checkLink(nextProps);
		this.setWebTitle(nextProps);
	}

	redirectIfTermsNotAcceptedYet(props){
		if(!props.data.loading){
			const {singleAccountEmail, singleInstitutionRegistrationByEmail, singleUnkownAccountEmail} = props.data;

			let acceptedNewestTerms = false;
			if(singleAccountEmail){
				acceptedNewestTerms = singleAccountEmail.AcceptedNewestTerms;
			} else if(singleInstitutionRegistrationByEmail){
				acceptedNewestTerms = singleInstitutionRegistrationByEmail.AcceptedNewestTerms;
			} else if(singleUnkownAccountEmail){
				acceptedNewestTerms = singleUnkownAccountEmail.AcceptedNewestTerms;
			}
			if(!acceptedNewestTerms && !this.isIntroPage(props) && localStorage.getItem("ErasmusCode") !== "euf"){
				console.log("send to terms and conditions");
				//this.props.history.push("/accept-new-conditions");
			}
		}
	}

	setWebTitle(props){
		let title = props.pathname;
		title = title.substr(1);
		title = title.replace(/-/g,' ');
		const titleSplitArray = title.split("/");
		for(var i = 0 ; i < titleSplitArray.length ; i++){
			let text = titleSplitArray[i].charAt(0).toUpperCase() + titleSplitArray[i].substr(1);
			if(i > 1){
				text = "Detail";
			}
	        titleSplitArray[i] = text;
	    }
		titleSplitArray.reverse();
		title = titleSplitArray.join(" | ");
		title += " • Erasmus+ dashboard";
		document.title = title;
	}

	checkLink(props){
		const url = props.pathname;
		if(!url.includes(GlobalVar.generateLinkUrl(intl, "Institutions", "Login"))
			&& !url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Confirmation"))
			&& !url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Login"))
			&& !url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Registration"))
			&& !url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Registration"))+"/termsAndConditions"
			&& !url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Confirmation"))
			&& url !== "/intro"
			&& url !== GlobalVar.generateLinkUrl(intl, "Account", "Password Forgotten")
			&& !url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Reset Password"))
			&& !url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Reset Password"))
			&& !url.includes("/useful-information")) {

			if(localStorage.getItem("ErasmusCode") == null && localStorage.getItem("unknownEmail") == null){
				console.log("redirected");
				this.props.history.replace({pathname:"/account/login", state:{ restricted: true, urlRestricted: url }});
			}
		}
		if(url === "/"){
			if(localStorage.getItem("ErasmusCode") == null && localStorage.getItem("unknownEmail") == null){
				console.log("redirected");
				this.props.history.replace({pathname:"/account/login"});
			}
		}
		if(url === "/" || url.includes("/direct-login")){
			if(localStorage.getItem("ErasmusCode") !== null){
				if(localStorage.getItem("ErasmusCode") === "aaa"){
					window.location.replace("/institutions/pendingrequests");
					return;
				}
				window.location.replace(GlobalVar.generateLinkUrl(intl, "My University", "General info"));
			}
		}
	}

	logout(){
		localStorage.clear();
		this.props.history.replace("/account/login");
	}

	generateLinkUrl(base, detail){
		let url = "";
		let today = new Date();
		let june = new Date(2021, 5, 2);
		base = intl.get(base);
		detail = intl.get(detail);
		base = base.replace(/\s/g, "-");
		detail = detail.replace(/\s/g, "-");
		if(base === "OLA3" &&
			(detail === "Outgoing-students" || 
			detail === "Incoming-students")){
			if(today.toJSON().slice(0, 10) < june.toJSON().slice(0, 10)){
				// Before 1st June
				let previousYear = today.getFullYear()-1;
				url = "/"+base+"/"+detail+"/"+previousYear+"-"+today.getFullYear();
			} else {
				// After 1st June
				let nextYear = today.getFullYear()+1;
				url = "/"+base+"/"+detail+"/"+today.getFullYear()+"-"+nextYear;
			}
		} else {
			url = "/"+base+"/"+detail;
		}
		url = url.toLowerCase(url);
		/*if(GlobalVar.getParameterByName("lang") !== undefined){
			return url+"?lang="+GlobalVar.getParameterByName("lang");
		} else {
			return url;
		}*/
		return url;
	}

	generateActive(base, detail){
		let testUrl = this.generateLinkUrl(base, detail);
		testUrl = testUrl.replace(/\?lang.+/, "");

		if(this.props.pathname.includes(testUrl)){
			return "active";
		}
		return "";
	}

	generateFunctionDynamicLICustom(functionName, icon, divisionTitle, elementTitle, customName){
		if(localStorage.getItem("unknownEmail") === null || elementTitle == "Student List"){
			if(functionName === ""){
				return <Link to={this.generateLinkUrl(divisionTitle, elementTitle)}><li><img alt="" src={icon} /><p className={this.generateActive(divisionTitle, elementTitle)}>{intl.get(customName)}</p></li></Link>;
			}
			if(GlobalVar.checkAccountFunction(functionName)){
				return <Link to={this.generateLinkUrl(divisionTitle, elementTitle)}><li><img alt="" src={icon} /><p className={this.generateActive(divisionTitle, elementTitle)}>{intl.get(customName)}</p></li></Link>;
			}
		}
		return <Link to={this.generateLinkUrl('Error', elementTitle)}><li><img alt="" className="disabled" src="/icons/remove.svg" /><p className="disabled">{intl.get(customName)}</p></li></Link>;
	}

	generateFunctionDynamicLI(functionName, icon, divisionTitle, elementTitle){
		if(localStorage.getItem("unknownEmail") === null || elementTitle == "Student List"){
			if(functionName === ""){
				return <Link to={this.generateLinkUrl(divisionTitle, elementTitle)}><li><img alt="" src={icon} /><p className={this.generateActive(divisionTitle, elementTitle)}>{intl.get(elementTitle)}</p></li></Link>;
			}
			if(GlobalVar.checkAccountFunction(functionName)){
				return <Link to={this.generateLinkUrl(divisionTitle, elementTitle)}><li><img alt="" src={icon} /><p className={this.generateActive(divisionTitle, elementTitle)}>{intl.get(elementTitle)}</p></li></Link>;
			}
		}
		return <Link to={this.generateLinkUrl('Error', elementTitle)}><li><img alt="" className="disabled" src="/icons/remove.svg" /><p className="disabled">{intl.get(elementTitle)}</p></li></Link>;
	}

	isIntroPage(props){
		let url = props.pathname;
		if(url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Confirmation")) || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Login")) || url === "/intro" || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Registration")) || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Confirmation")) || url === GlobalVar.generateLinkUrl(intl, "Account", "Password Forgotten") || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Reset Password")) || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Reset Password")) || url.includes("/accept-new-conditions") || url.includes("/useful-information")){
			return true;
		}
		return false;
	}
	render() {
		if(this.isIntroPage(this.props)){
			return (
	    		<div>
	    		</div>
	  		);
		}
		if(localStorage.getItem("ErasmusCode") !== null){
            if(GlobalVar.decryptWithKey(localStorage.getItem("ErasmusCode")) === "EUFadmin"){
            	return (
		    		<nav className="left_navigation_bar">
						<ul>
							<li>{intl.get('Institutions')}</li>
							<li>
								<ul>
									<Link to="/institutions/pendingrequests"><li><p>Pending requests</p></li></Link>
									<Link to="/institutions/acceptedrequests"><li><p>Accepted requests</p></li></Link>
									<Link to="/institutions/declinedrequests"><li><p>Declined requests</p></li></Link>
								</ul>
							</li>
							{/*<li>{intl.get('Institution data')}</li>
							<li>
								<ul>
									<Link to="/institution-data/adding-data"><li><p>Adding data</p></li></Link>
								</ul>
							</li>*/}
							<li>{intl.get('Documentation')}</li>
							<li>
								<ul>
									<Link to="/frequentlyAskedQuestions"><li><p>FAQs</p></li></Link>
									<Link to="/documentation"><li><p>Documentation</p></li></Link>
									<Link to="/termsAndConditions"><li><p>Terms and conditions</p></li></Link>
									<Link to="/tutorials"><li><p>Tutorial videos</p></li></Link>
								</ul>
							</li>

							<li>{intl.get('Other')}</li>
							<li>
								<ul>
									<li onClick={() => this.logout()} className="clickable"><p>Logout</p></li>
								</ul>
							</li>
						</ul>
					</nav>
	      		);
            }
        }
    	return (
    		<nav className="left_navigation_bar">
				<ul>
					<li>{intl.get('My University')}</li>
					<li>
						<ul>
							{this.generateFunctionDynamicLI("", "/icons/home.svg", "My University", "General info")}
							{this.generateFunctionDynamicLI("creatingAccounts", "/icons/profile.svg", 'My University', 'Accounts and Access')}
							{this.generateFunctionDynamicLI("managingOUnits", "/icons/ounits.png", 'My University', 'Organizational Units')}
							{/* {this.generateFunctionDynamicLI("erasmusInformation", "/icons/documentation.svg", 'My University', 'Erasmus Information')} */}
							{this.generateFunctionDynamicLI("", "/icons/settings.png", "Other", "My settings")}
							{this.generateFunctionDynamicLI("covidRestrictions", "/icons/questionMark-slim.svg", "My University", "Covid-19")}
							{this.generateFunctionDynamicLI("", "/icons/questionMark-slim.svg", "My University", "Ukraine")}
							<li onClick={() => this.logout()} className="clickable"><img alt="" src="/icons/logout.svg" /><p>Logout</p></li>
						</ul>
					</li>
					<li>{intl.get('OLA3')}</li>
					<li>
						<ul>
							{this.generateFunctionDynamicLICustom("managingStudents,viewingStudents", "/icons/searchBubble.svg", "OLA3Link", "Outgoing students","Outgoing students")}
							{this.generateFunctionDynamicLICustom("managingStudents,viewingStudents", "/icons/searchBubble.svg", "OLA3Link", "Incoming students","Incoming students")}
							{this.generateFunctionDynamicLICustom("uploadingStudents", "/icons/uploadBubble.svg", "OLA3Link", "Upload","Upload")}
						</ul>
					</li>
					<li>{intl.get('Short-Term-Mobilities')}</li>
					<li>
						<ul>
							{this.generateFunctionDynamicLICustom("managingStudents,viewingStudents", "/icons/searchBubble.svg", "ShortTermMobilitiesLink", "Outgoing students","Outgoing students")}
							{this.generateFunctionDynamicLICustom("managingStudents,viewingStudents", "/icons/searchBubble.svg", "ShortTermMobilitiesLink", "Incoming students","Incoming students")}
						</ul>
					</li>
					<li>{intl.get('OLA2')}</li>
					<li>
						<ul>
							{this.generateFunctionDynamicLICustom("managingStudents,viewingStudents", "/icons/searchBubble.svg", "Outgoing students", "Student List", "Outgoing students")}
							{/*{this.generateFunctionDynamicLICustom("editingStepByStep", "/icons/stepByStep.svg", "Outgoing students", "App step by step", "App steps outgoing")}*/}
							{this.generateFunctionDynamicLICustom("managingStudents,viewingStudents", "/icons/searchBubble.svg", "Incoming students", "Student List", "Incoming students")}
							{/*{this.generateFunctionDynamicLICustom("editingStepByStep", "/icons/stepByStep.svg", "Incoming students", "App step by step", "App steps incoming")}*/}
						</ul>
					</li>
					<li>{intl.get('Erasmus-App')}</li>
					<li>
						<ul>
							{this.generateFunctionDynamicLI("erasmus-app", "/icons/list.png", "Erasmus-App", "Useful Info")}
							{/*this.generateFunctionDynamicLI("createUsefulInfo", "/icons/plus.svg", "Erasmus-App", "Create Useful Information")*/}
							{this.generateFunctionDynamicLI("erasmus-app", "/icons/list.png", "Erasmus-App", "Deals")}
							{this.generateFunctionDynamicLI("erasmus-app", "/icons/list.png", "Erasmus-App", "Events")}
							{/*this.generateFunctionDynamicLI("erasmus-app", "/icons/plus.svg", "Erasmus-App", "Create a new Deal")*/}
							{/*this.generateFunctionDynamicLI("erasmus-app", "/icons/plus.svg", "Erasmus-App", "Create a new Event")*/}
						</ul>
					</li>
          <li>{intl.get('Nominations')}</li>
					<li>
						<ul>
							{this.generateFunctionDynamicLI("nominations", "/icons/searchBubble.svg", "Nominations", "outgoingNominations")}
							{this.generateFunctionDynamicLI("nominations", "/icons/searchBubble.svg", "Nominations", "incomingNominations")}
							{this.generateFunctionDynamicLI("nominations", "/icons/plus.svg", "Nominations", "NominationRequirements")}
						</ul>
					</li>
					<li>{intl.get('IIAm')}</li>
					<li>
						<ul>
							{this.generateFunctionDynamicLI("managingIIAs", "/icons/list.png", "IIAm", "IIA List")}
							{this.generateFunctionDynamicLI("creatingIIA", "/icons/plus.svg", "IIAm", "New Digital IIA")}
							{this.generateFunctionDynamicLI("importIIA", "/icons/uploadBubble.svg", "IIAm", "Import IIA")}
							{/* {this.generateFunctionDynamicLI("creatingIIA", "/icons/uploadBubble.svg", "IIAm", "New Digital IIA")} */}
							{this.generateFunctionDynamicLI("defaultIIAData", "/icons/settings.png","IIAm", "Default IIA Data")}
							{/* {this.generateFunctionDynamicLI("factsheet", "/icons/factsheet.png","IIAm", "Factsheet")} */}
							<Link to={this.generateLinkUrl("IIAm", "FAQs")}><li><img alt="" src="/icons/questionMark-slim.svg" /><p className={this.generateActive("IIAm", "FAQs")}>{intl.get('FAQs')}</p></li></Link>
						</ul>
					</li>
					<li>{intl.get('EWP')}</li>
					<li>
						<ul>
							<li>
								<img alt="" src="/icons/EWP_logo.png"/>
								<a href="https://www.erasmuswithoutpaper.eu/" target="_blank" rel="noopener noreferrer"><p className={this.generateActive("EWP", "Website")}>Website</p></a>
							</li>
							{this.generateFunctionDynamicLI("ewpSettings", "/icons/settings.png", "EWP", "Settings")}
							{/* {this.generateFunctionDynamicLI("", "/icons/home.svg", "My University", "General info")}
							{this.generateFunctionDynamicLI("creatingAccounts", "/icons/profile.svg", 'My University', 'Accounts and Access')*/}
						</ul>
					</li>
					<li>{intl.get('Support')}</li>
					<li>
						<ul>

							<Link to={this.generateLinkUrl("Support", "FAQs")}><li><img alt="" src="/icons/questionMark-slim.svg" /><p className={this.generateActive("Support", "FAQs")}>{intl.get('FAQs')}</p></li></Link>
							<Link to={this.generateLinkUrl("Support", "Tutorials")}><li><img alt="" src="/icons/tutorials.svg" /><p className={this.generateActive("Support", "Tutorials")}>{intl.get('Tutorials')}</p></li></Link>
						  	<Link to={this.generateLinkUrl("Support", "Documentation")} ><li><img alt="" src="/icons/documentation.svg" /><p className={this.generateActive("Support", "Documentation")}>{intl.get('Documentation')}</p></li></Link>
						  {/*<Link to={this.generateLinkUrl("Support", "Terms and Conditions")} ><li><img src="/icons/documentation.svg" /><p className={this.generateActive("Support", "Terms and Conditions")}>{intl.get('Terms and Conditions')}</p></li></Link>*/}
						</ul>
					</li>
					<li>External Services</li>
					<li>
						<ul>
							{/*<li>
								<img alt="" src="/icons/link.svg" />

								<a href="https://europeanstudentcard.eu" target="_blank" rel="noopener noreferrer"><p className={this.generateActive("ESC", "Website")}>ESC</p></a>
							</li>*/}
							<li>
								<img alt="" src="/icons/link.svg"/>
								<a href="http://egracons.eu/" target="_blank" rel="noopener noreferrer"><p className={this.generateActive("EGRACONS", "Website")}>EGRACONS</p></a>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
  		);
    }

};

export default Navigation;

//export default graphql(query)(Navigation);
//Before enabling this, check account permission, since it won't work so the query to get functions should be moved to this component
