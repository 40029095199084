import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import '../style/css/main.css';
import intl from 'react-intl-universal';
import { Query } from 'react-apollo';

import Header from './general/Header';
import Navigation from './general/Navigation';
import IntroBackground from './general/IntroBackground';

import GeneralInfo from './institutions/generalInfo/GeneralInfo';
import OUnits from './institutions/ounits/OUnits';
import CovidSettings from './Covid19/Settings';
import UkraineAssistanceSettings from './ukraine-assistance/Settings';
import Upload from './students/upload/Upload';
import UploadSucces from './students/upload/UploadSucces';
/*import StepByStep from './students/stepByStep/StepByStep';*/
import GlobalVar from '../GlobalVar';
import List from './students/list/List';
import Accounts from './institutions/accounts/Accounts';
// import Factsheet from './iia/creation/factsheet/Factsheet';
import DefaultIIAData from './iia/creation/defaultData/DefaultIIAData';
import UsefulInformationView from './iia/creation/factsheet/UsefulInformationView';
import IIACreation from './iia/creation/IIACreation';
import ImportIIA from './iia/creation/ImportIIA';
// import Creation from './iia/creation/Creation';
// import CreationSuccess from './iia/creation/CreationSuccess';
import FAQs from './iia/creation/FAQs';
import IIAList from './iia/list/IIAList';
// import DeleteIIAm from './iia/creation/IIADeletion';
import Edit from './iia/list/edition/Edit';
// import IIAPopup from './iia/list/popup/Popup';
import Settings from './EWP/settings/Settings';

import OLA3 from './ola3/List';
import UploadOLA from './ola3/Upload';
import ExportLAPDF from './ola3/ExportPDF';
import SingleOLA from './ola3/popup/SingleOLA';

import deals from './deal_events/beta/creation/form/deals';
import events from './deal_events/beta/creation/form/events';
import createDeal from './deal_events/beta/creation/form/createNewDeal';
import createEvent from './deal_events/beta/creation/form/createNewEvent';
import usefulInfo from './deal_events/beta/creation/form/usefulInfo';
import createUsefulInfo from './deal_events/beta/creation/form/createUsefulInfo';

import outgoingNominations from './nominations/outgoing/List';
import outgoingNomination from './nominations/outgoing/Nominations';
import incomingNominations from './nominations/incoming/List';
import incomingNomination from './nominations/incoming/Nominations';
import ExportNominationsPDF from './nominations/ExportNominationsPDF';
import ExportNominationsZIP from './nominations/ExportNominationsZIP';
import NominationRequirements from './nominations/NominationRequirements';
import NominationRequirementsAccess from './nominations/NominationRequirementsAccess';
import ExportNominationsInZIP from './nominations/ExportNominationInZIP';

import ShortTermList from './shortterm/List';
import ShortTerm from './shortterm/singleview/ShortTerm';
import ExportShortTermPDF from './shortterm/ExportPDF';

import MyAccount from './other/myAccount/MyAccount';
import AccountConfirmation from './authentication/confirmation/AccountConfirmation';
import InstitutionConfirmation from './authentication/confirmation/InstitutionConfirmation';
import AccountLogin from './authentication/login/AccountLogin';
import RequestPassword from './authentication/resetPassword/RequestPassword';
import ResetPassword from './authentication/resetPassword/ResetPassword';
import InstitutionRegistration from './authentication/registration/InstitutionRegistration';
import Terms from './authentication/registration/popup/Terms';
import NoAccess from './general/NoAccess';
import Intro from './Intro';

import Faqs from './support/faqs/Faqs';
import TermsAndConditions from './support/termsAndConditions/TermsAndConditions';

import DocumentList from './support/documentation/DocumentList';
import Document from './support/documentation/Document';
import Tutorials from './support/tutorials/Tutorials';

import VerifyRequests from './EUF/institutions/VerifyRequests';
import FrequentlyAskedQuestions from './EUF/Documentation/FrequentlyAskedQuestions';
import Documentation from './EUF/Documentation/Documentation';
import EUFtutorials from './EUF/Documentation/Tutorials';

/*import AddingData from './EUF/institutionData/addingData/AddingData.js';*/

import AcceptNewConditions from './general/acceptNewConditions/AcceptNewConditions';

import Conditions from './EUF/TermsAndConditions/Conditions';
import { graphql } from 'react-apollo';
import { query } from '../queries/getSingleAccountFunctions';
import ShowInfo from './iia/list/popup/ShowInfo';
import ShowEWPIIAList from './iia/list/filters/ShowEWPIIAList';
import ShowIIAsToImport from './iia/creation/ShowIIAsToImport';

class Main extends Component {
  constructor(props) {
    super(props);
    this.addToHeaderTitle = this.addToHeaderTitle.bind(this);
    this.state = {
      openNavigation: false
    }
    this.openOrCloseNavigation = this.openOrCloseNavigation.bind(this);
  }
  componentWillMount() {
    this.redirect();
    GlobalVar.verifyToken(this.props.history);
    //this.startErrorLog();
  }

  openOrCloseNavigation(event) {
    event.preventDefault();
    this.setState({
      openNavigation: !this.state.openNavigation
    });
  }

  startErrorLog() {
    window.onerror = (errorMsg, url, lineNumber) => {
      window.location.replace("/intro");
      return true;
    }
  }

  redirect() {
    console.log({ "Hostname": window.location.hostname });
    if (window.location.hostname === "www.erasmusplus-dashboard.eu") {
      window.location.assign('https://www.erasmus-dashboard.eu');
      return;
    }
    else if (window.location.hostname.includes("erasmus-dashboard.eu")) {
      if (!window.location.href.includes("https://www.")) {
        let newLink = window.location.href.substring(window.location.protocol.length + 2);
        newLink = newLink.replace("www.", "");
        newLink = `https://www.${newLink}`;
        console.log({ newLink });
        window.location.replace(newLink);
      }
    }
  }
  addToHeaderTitle(text) {
    this._child.addToPageTitle(text);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data.loading) {
      if (nextProps.data.singleAccount !== null) {
        GlobalVar.accountfunctions = nextProps.data.singleAccount;
      }
    }
  }

  generateFunctionDependantRoute(functionName, divisionTitle, elementTitle, component) {
    if (localStorage.getItem("unknownEmail") === null || elementTitle == "Student List" || elementTitle == "IIA List") {
      if (functionName === "") {
        return <Route path={GlobalVar.generateLinkUrl(intl, divisionTitle, elementTitle)} component={component} />;
      }
      if (GlobalVar.checkAccountFunction(functionName)) {
        return <Route path={GlobalVar.generateLinkUrl(intl, divisionTitle, elementTitle)} component={component} />;
      }
      else {
        return <Route exact path={GlobalVar.generateLinkUrl(intl, divisionTitle, elementTitle)} component={NoAccess} />
      }
    }
  }

  generateEUFfunctions() {
    if (localStorage.getItem("ErasmusCode") !== null) {
      if (GlobalVar.decryptWithKey(localStorage.getItem("ErasmusCode")) === "EUFadmin") {
        return (
          <div className="useless_div">
            <Route path="/institutions/pendingrequests" render={() => <VerifyRequests valid={0} />} />
            <Route path="/institutions/acceptedrequests" render={() => <VerifyRequests valid={1} />} />
            <Route path="/institutions/declinedrequests" render={() => <VerifyRequests valid={-1} />} />
            {/*<Route path="/institution-data/adding-data" exact component={AddingData} />
            <Route path="/institution-data/adding-data/:countryID" exact component={AddingData} />
            <Route path="/institution-data/adding-data/:countryID/:cityID" exact component={AddingData} />
            <Route path="/institution-data/adding-data/:countryID/:cityID/:institutionID" exact component={AddingData} />*/}

            <Route path="/frequentlyAskedQuestions" render={() => <FrequentlyAskedQuestions />} />
            <Route path="/documentation" render={() => <Documentation />} />
            <Route path="/termsAndConditions" render={() => <Conditions />} />
            <Route path="/tutorials" render={() => <EUFtutorials />} />
          </div>
        );
      }
    }
  }

  generateBackroundImage() {
    const url = this.props.location.pathname;

    if (url === "/intro" || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Login")) || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Confirmation")) || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Registration")) || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Confirmation")) || url === GlobalVar.generateLinkUrl(intl, "Account", "Password Forgotten") || url.includes(GlobalVar.generateLinkUrl(intl, "Institution", "Reset Password")) || url.includes(GlobalVar.generateLinkUrl(intl, "Account", "Reset Password"))) {
      return <IntroBackground />;
    }
    return "";
  }

  openNavigation() {
    if (this.state.openNavigation) {
      return "open_content";
    }
    return "";
  }

  checkBectyl() {
    let pattern = new RegExp("^([A-Z] [A-Z]+)");

    if (localStorage.getItem("ErasmusCode") && pattern.test(GlobalVar.decryptWithKey(localStorage.getItem("ErasmusCode")))) {
      alert("Due to recent software updates on the Erasmus Dashboard, you've been logged out automatically in order to reset your session data. Please log in again to resume your work."
        + "\nIf you see this message again, can't access your account or encounter unusual behavior, please contact us via dashboard@uni-foundation.eu."
        + "\nSorry for the inconvenience and thank you for your cooperation.");

      console.log("BECTYL found. Logging out...");
      localStorage.clear();
      this.props.history.replace("/account/login");
    }
  }

  render() {
    this.props.history.listen((location, action) => {
      if (this.state.openNavigation) {
        this.setState({ openNavigation: false });
      }
    });

    return (
      <div className={`main ${this.openNavigation()}`}>
        {this.generateBackroundImage()}

        <Header openNavigation={this.state.openNavigation} openOrCloseNavigation={this.openOrCloseNavigation} pathname={this.props.location.pathname} ref={(child) => { this._child = child; }} />
        <Navigation openNavigation={this.state.openNavigation} history={this.props.history} pathname={this.props.location.pathname} />
        <div onClick={() => this.setState({ openNavigation: false })} className="black_background_content"></div>
        {this.props.children}

        <Route exact path="/intro" component={Intro} />
        <Route path={"/general-info/useful-information/:fcode"} component={UsefulInformationView} />

        {this.generateFunctionDependantRoute("", "My University", "General info", GeneralInfo)}
        {this.generateFunctionDependantRoute("creatingAccounts", "My University", "Accounts and Access", Accounts)}
        {this.generateFunctionDependantRoute("managingOUnits", "My University", "Organizational Units", OUnits)}
        {this.generateFunctionDependantRoute("covidRestrictions", "My University", "Covid-19", CovidSettings)}
        {this.generateFunctionDependantRoute("", "My University", "Ukraine", UkraineAssistanceSettings)}

        {/* {this.generateFunctionDependantRoute("uploadingStudents", "IIAm", "Creation of IIA", Creation)} */}
        {this.generateFunctionDependantRoute("managingIIAs", "IIAm", "IIA List", IIAList)}
        {this.generateFunctionDependantRoute("creatingIIA", "IIAm", "New Digital IIA", IIACreation)}
        {this.generateFunctionDependantRoute("importIIA", "IIAm", "Import IIA", ImportIIA)}
        {/* {this.generateFunctionDependantRoute("creatingIIA", "IIAm", "Create Digital Inter-institutional Agreement", IIACreation)} */}
        {this.generateFunctionDependantRoute("defaultIIAData", "IIAm", "Default IIA Data", DefaultIIAData)}
        {/* {this.generateFunctionDependantRoute("factsheet", "IIAm", "Factsheet", Factsheet)} */}
        {/* {this.generateFunctionDependantRoute("", "Useful Information", "Useful Information", UsefulInformationView)} */}
        {/* {this.generateFunctionDependantRoute("creatingIIA", "IIAm", "Creation success", CreationSuccess)} */}

        {this.generateFunctionDependantRoute("erasmus-app", "Erasmus-App", "Useful Info", usefulInfo)}
        {this.generateFunctionDependantRoute("erasmus-app", "Erasmus-App", "Deals", deals)}
        {this.generateFunctionDependantRoute("erasmus-app", "Erasmus-App", "Events", events)}
        {this.generateFunctionDependantRoute("erasmus-app", "Erasmus-App", "Create Useful Information", createUsefulInfo)}
        {this.generateFunctionDependantRoute("erasmus-app", "Erasmus-App", "Create a New Deal", createDeal)}
        {this.generateFunctionDependantRoute("erasmus-app", "Erasmus-App", "Create a New Event", createEvent)}

        {this.generateFunctionDependantRoute("nominations", "Nominations", "outgoingNominations", outgoingNominations)}
        {this.generateFunctionDependantRoute("nominations", "Nominations", "outgoingNomination", outgoingNomination)}
        <Route exact path={"/outgoing-student/application/:id"} component={outgoingNomination} />
        <Route exact path={"/outgoing-student/export/:pdfUrl"} component={ExportNominationsPDF} />
        <Route exact path={"/outgoing-student/exportzip/:zipUrl"} component={ExportNominationsZIP} />
        <Route exact path={"/incoming-student/exportzip/:zipUrl"} component={ExportNominationsInZIP} />

        {this.generateFunctionDependantRoute("nominations", "Nominations", "incomingNominations", incomingNominations)}
        {this.generateFunctionDependantRoute("nominations", "Nominations", "incomingNomination", incomingNomination)}
        <Route exact path={"/incoming-student/application/:id"} component={incomingNomination} />

        {this.generateFunctionDependantRoute("nominations", "Nominations", "NominationRequirements", NominationRequirementsAccess)}


        {this.generateFunctionDependantRoute("managingStudents,viewingStudents", "Outgoing students", "Student List", List)}
        {this.generateFunctionDependantRoute("managingStudents,viewingStudents", "Incoming students", "Student List", List)}

        {/*this.generateFunctionDependantRoute("editingStepByStep", "Incoming students", "App step by step", StepByStep)
        this.generateFunctionDependantRoute("editingStepByStep", "Outgoing students", "App step by step", StepByStep)*/}

        {/*{GlobalVar.checkAccountFunction("editingStepByStep")&&<Route path={GlobalVar.generateLinkUrl(intl, "Outgoing students", "App step by step")+"/:sectionID/:pointName"} component={StepByStep} addToHeaderTitle={this.addToHeaderTitle}/>}
        {GlobalVar.checkAccountFunction("editingStepByStep")&&<Route exact path={GlobalVar.generateLinkUrl(intl, "Outgoing students", "App step by step")} component={StepByStep} addToHeaderTitle={this.addToHeaderTitle} />}
        {GlobalVar.checkAccountFunction("editingStepByStep")&&<Route path={GlobalVar.generateLinkUrl(intl, "Incoming students", "App step by step")+"/:sectionID/:pointName"} component={StepByStep}/>}
        {GlobalVar.checkAccountFunction("editingStepByStep")&&<Route exact path={GlobalVar.generateLinkUrl(intl, "Incoming students", "App step by step")} component={StepByStep}/>}*/}

        {/*<Route path={GlobalVar.generateLinkUrl(intl, "Outgoing students", "Student List")} component={List}/>
        <Route path={GlobalVar.generateLinkUrl(intl, "Incoming students", "Student List")} component={List}/>
        <Route path={GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List")} component={IIAList}/>*/}
        {/* <Route path={GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List")+"/edit/:iiaId"} component={IIAEdit}/> */}
        {GlobalVar.checkAccountFunction("managingIIAs") &&
          <Route props={this.props} path={GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/details/:iiaId"} component={ShowInfo} />}
        {GlobalVar.checkAccountFunction("managingIIAs") &&
          <Route props={this.props} path={GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/ewp-iia-list"} component={ShowEWPIIAList} />}
        {GlobalVar.checkAccountFunction("managingIIAs") &&
          <Route props={this.props} path={GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List") + "/edit/:iiaId"} component={Edit} />}
        {GlobalVar.checkAccountFunction("managingIIAs") &&
          <Route path={GlobalVar.generateLinkUrl(intl, "IIAm", "Import IIA") + "/file-details"} component={ShowIIAsToImport} />}
        {/* {GlobalVar.checkAccountFunction("managingIIAs") &&
          <Route props={this.props} path={GlobalVar.generateLinkUrl(intl, "IIAm", "IIA List")+"/iia-detail/:iiaId"} component={IIAPopup}/>} */}
        <Route props={this.props} path={GlobalVar.generateLinkUrl(intl, "IIAm", "FAQs")} component={FAQs} />

        <Route path={GlobalVar.generateLinkUrl(intl, "EWP", "Settings")} component={Settings} />

        <Route path={GlobalVar.generateLinkUrl(intl, "Support", "FAQs")} component={Faqs} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Support", "Documentation")} component={DocumentList} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Support", "Documentation") + "/title/:title"} component={Document} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Support", "Documentation")} component={Documentation} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Support", "Terms and Conditions")} component={TermsAndConditions} />

        <Route path={GlobalVar.generateLinkUrl(intl, "Support", "Tutorials")} component={Tutorials} />

        <Route path={GlobalVar.generateLinkUrl(intl, "Other", "My settings")} component={MyAccount} />

        <Route path={GlobalVar.generateLinkUrl(intl, "Account", "Confirmation") + "/:accountID"} component={AccountConfirmation} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Account", "Login")} component={AccountLogin} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Account", "Login") + "/termsandconditions"} render={() => <Terms history={this.props.history} />} />
        <Route path={GlobalVar.generateLinkUrl(intl, "Institution", "Confirmation") + "/:institutionID"} component={InstitutionConfirmation} />

        <Route exact path={GlobalVar.generateLinkUrl(intl, "Account", "Password Forgotten")} component={RequestPassword} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Institution", "Reset Password") + "/:ID"} component={ResetPassword} />
        <Route exact path={GlobalVar.generateLinkUrl(intl, "Account", "Reset Password") + "/:ID"} component={ResetPassword} />

        <Route path={GlobalVar.generateLinkUrl(intl, "Institution", "Registration")} component={InstitutionRegistration} />

        <Route exact path={`/${intl.get('Error')}/:pageName`} component={NoAccess} />

        <Route exact path={`/accept-new-conditions`} component={AcceptNewConditions} />

        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "OLA3Link", "Outgoing students") + "/:firstYear-:secondYear"} component={OLA3} />}
        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "OLA3Link", "Outgoing students")} component={OLA3} />}
        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "OLA3Link", "Incoming students") + "/:firstYear-:secondYear"} component={OLA3} />}
        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "OLA3Link", "Incoming students")} component={OLA3} />}
        {this.generateFunctionDependantRoute("uploadingStudents", "OLA3Link", "Upload", UploadOLA)}
        <Route exact path={"/ola3/export/:id"} component={ExportLAPDF} />
        <Route exact path={"/ola3/export/:id/:vid"} component={ExportLAPDF} />
        <Route exact path={"/outgoing-student/ola/:olaID"} component={SingleOLA} />
        <Route exact path={"/incoming-student/ola/:olaID"} component={SingleOLA} />

        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "ShortTermMobilitiesLink", "Outgoing students") + "/:firstYear-:secondYear"} component={ShortTermList} />}
        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "ShortTermMobilitiesLink", "Outgoing students")} component={ShortTermList} />}
        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "ShortTermMobilitiesLink", "Incoming students") + "/:firstYear-:secondYear"} component={ShortTermList} />}
        {GlobalVar.checkAccountFunction("managingStudents,viewingStudents") &&
          <Route exact path={GlobalVar.generateLinkUrl(intl, "ShortTermMobilitiesLink", "Incoming students")} component={ShortTermList} />}
        <Route exact path={"/outgoing-student/short-term/:shortTermID"} component={ShortTerm} />
        <Route exact path={"/incoming-student/short-term/:shortTermID"} component={ShortTerm} />
        <Route exact path={"/short-term/export/:id"} component={ExportShortTermPDF} />
        <Route exact path={"/short-term/export/:id/:vid"} component={ExportShortTermPDF} />

        {this.checkBectyl()}
        {this.generateEUFfunctions()}
      </div>
    );
  }
}

export default graphql(query, {
  options: (props) => ({
    variables: { id: localStorage.getItem("accountID") }
  })
})(Main);
